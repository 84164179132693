<lib-header [bgImage]="'assets/shared/img/misc/header_woman_daughter.png'" [classes]="'header--small'" [inputModel]="term" [displaySearchBar]="true" [displayContactButton]="false" [title]="'recent-deceased' | translate"></lib-header>

<div class="container">
    <div class="search-deceased-wrap">
        <div class="deceased-wrap" [class.mt-0]="!ready">
            <ng-container *ngIf="ready">
                <ng-container *ngFor="let dec of deceased">
                    <lib-deceased-card [size]="'big'" [deceased]="dec"></lib-deceased-card>
                </ng-container>
                <div [class.mt-120]="deceased.length === 0 && !loading" *ngIf="deceased.length === 0 && !loading">
                    {{'no-results-deceased' | translate}}
                </div>
            </ng-container>
        </div>
        <div *ngIf="!ready || loading" class="spinner-wrap">
            <lib-spinner></lib-spinner>
        </div>
        <div (click)="loadExtra()" class="load" *ngIf="showMore && !loading && ready">
            {{'load-more' | translate}}
        </div>
    </div>
</div>