import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from './config.service';
import { DefaultService } from 'src/app/utils/api';
declare const grecaptcha: any;

@Injectable({
    providedIn: 'root'
})
export class TranslatorService {
    private translations$ = new BehaviorSubject<Object>({});
    private language$ = new BehaviorSubject<any>(null);
    private ready: boolean = false;
    private publicLanguage$ = new BehaviorSubject<string>(null);

    constructor(
        private DefaultService: DefaultService,
        public Route: ActivatedRoute,
        private ConfigService: ConfigService
    ) {
        const publicLanguage = localStorage.getItem('publicLanguage');
        if (publicLanguage) {
            this.setPublicLanguage(publicLanguage);
        }
    }

    public init(language?: any) {
        return new Promise<any>(async (res) => {
            if (!language) {
                language = this.getPublicLanguage();
            }
            this.setLanguage(language);
            moment.locale(this.getLocale(language));
            // let that = this;
            // let config = that.ConfigService.getConfig();
            // grecaptcha.ready(function () {
            //     grecaptcha.execute(config.captcha_public_key, { action: 'submit' }).then(async function (token) {
            //         that.ConfigService.setConfig({ ...config, token: token });
            let allLanguagesString = await this.DefaultService.languageGetAllStringForCurrentLanguage().pipe(
                tap((next) => {
                    this.translations$.next(next);
                    this.ready = true;
                })
            );
            return res(allLanguagesString);
            //     });
            // });
            // service.captchaCall().then(res => {
            //     let allLanguagesString = await that.DefaultService.languageGetAllStringForCurrentLanguage().pipe(
            //         tap((next) => {
            //             that.translations$.next(next);
            //             that.ready = true;
            //         })
            //     );
            //     return res(allLanguagesString);
            // })
        });
    }

    public getTranslation(key: string): string {
        if (!key || !this.ready) return key;
        key = key.toLowerCase();
        if (this.translations$.getValue()[key]) {
            return this.translations$.getValue()[key];
        } else {
            this.DefaultService.languageAddNewString({ code: key }).subscribe();
            return key;
        }
    }

    public getTranslations(): Observable<Object> {
        return this.translations$.asObservable();
    }

    public fetchAllTranslations(): Observable<Object> {
        return this.DefaultService.languageGetAllString();
    }

    public updateTranslation(FORM: any): Observable<Object> {
        return this.DefaultService.languageUpdateSingleString(FORM);
    }

    public getLanguage(): Observable<any> {
        return this.language$.asObservable();
    }

    public setLanguage(language: any) {
        this.language$.next(language);
    }

    public getPublicLanguage(): string | null {
        let browserLang = null;
        let localstorageLang = null;
        if (navigator) {
            browserLang = navigator.language || navigator['userLanguage'];
        }
        if (browserLang && browserLang.indexOf('fr') != -1) {
            browserLang = 'fr';
        } else browserLang = null;
        if (localStorage.getItem('publicLanguage')) {
            localstorageLang = localStorage.getItem('publicLanguage');
        }
        return this.publicLanguage$.getValue() || localstorageLang || browserLang || 'nl';
    }

    public setPublicLanguage(language: any) {
        this.publicLanguage$.next(language);
        localStorage.setItem('publicLanguage', language);
    }

    public getCurrentLanguage() {
        return this.language$.getValue() || this.publicLanguage$.getValue() || 'nl';
    }

    public getLocale(language?) {
        if (!language) {
            language = this.language$.getValue();
        }
        switch (language) {
            case 'nl':
                return 'nl-BE';
            case 'fr':
                return 'fr-FR';
            case 'en':
                return 'en-GB';
            default:
                return 'nl-BE';
        }
    }
}
