<div class="header-wrap">
    <lib-header [bgImage]="'assets/shared/img/misc/header_woman_daughter.png'" [title]="headerText" [text]="('service-details-header1' | translate) + phone + ('service-details-header2' | translate)" [phone]="phone"></lib-header>
</div>
<div class="container">
    <div class="service-details-wrap">
        <div class="router-wrap">
            <router-outlet></router-outlet>
        </div>
        <div class="other-services page-title">
            <div class="title">
                {{'other-services-title' | translate}}
            </div>
            <div class="line"></div>
            <div class="full-services-wrap">
                <div class="full-services-wrap--left">
                    <lib-service-card-link [info]="myArr[0]"></lib-service-card-link>
                    <lib-service-card-link [info]="myArr[2]"></lib-service-card-link>
                </div>
                <div class="full-services-wrap--right">
                    <lib-service-card-link [info]="myArr[1]"></lib-service-card-link>
                    <lib-service-card-link [info]="myArr[3]"></lib-service-card-link>
                </div>
            </div>
        </div>
    </div>
</div>