<div class="card-wrap">
    <img [src]="img" [alt]="'img_' + title">
    <div class="text-wrap">
        <div class="title">
            {{title}}
        </div>
        <div class="text">
            {{text}}
        </div>
        <div>
            <!-- <a class="more-link hover-link" [routerLink]="redirect">Meer lezen</a> -->
            <a class="more-link hover-link" [routerLink]="redirect">{{'read-more' | translate}}</a>
        </div>
    </div>
</div>