import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'lib-service-card-link',
  templateUrl: './service-card-link.component.html',
  styleUrls: ['./service-card-link.component.scss']
})
export class ServiceCardLinkComponent implements OnInit {
    @Input() info: any;

    updated: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }


}
