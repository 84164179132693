<div class="header-wrap">
    <lib-header [bgImage]="'assets/shared/img/misc/header_woman_daughter.png'" [displayphone]="true" classes="contact-header" [displayContactButton]="false" [title]="'contact' | translate" [text]="'header-contact-text' | translate" [phone]="phone"></lib-header>
</div>
<div class="container">
    <div class="contact-wrap">
        <div class="contact-wrap-inner">
            <ng-container *ngIf="!send">
                <h1 class="contact-title page-title">{{'contact-title' | translate}}</h1>
                <div class="full-input-wrap">
                    <div class="input-wrap">
                        <lib-field-text [model]="FORMS.lastname" (modelChange)="FORMS.lastname = $event;" [error]="validation.lastname" [label]="'name-placeholder' | translate"></lib-field-text>
                        <lib-field-text [model]="FORMS.firstname" (modelChange)="FORMS.firstname = $event;" [error]="validation.firstname" [label]="'firstname-placeholder' | translate"></lib-field-text>
                    </div>
                    <div class="input-wrap">
                        <lib-field-text [model]="FORMS.phone" (modelChange)="FORMS.phone = $event;" [error]="validation.phone" [label]="'phone-placeholder' | translate"></lib-field-text>
                        <lib-field-text [model]="FORMS.email" (modelChange)="FORMS.email = $event;" [error]="validation.email" [label]="'mail-placeholder' | translate"></lib-field-text>
                    </div>
                    <lib-field-textarea [model]="FORMS.message" (modelChange)="FORMS.message = $event;" [error]="validation.message" [label]="'message-placeholder' | translate"></lib-field-textarea>
                </div>
                <lib-button (emitClick)="submit()" [disabled]="sending" [text]="'send-button-text' | translate: 'shared'" [classes]="'contact-button'"></lib-button>
            </ng-container>
            <ng-container *ngIf="send">
                <div>
                    <h1 class="contact-title">
                        {{'approval-send-title' | translate}}
                    </h1>
                </div>
    
                <div class="subtitle">
                    {{'approval-send-text' | translate}}
                </div>
            </ng-container>
        </div>
    </div>
</div>
