import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeceasedCardComponent } from './deceased-card.component';
import { RouterModule } from '@angular/router';
import { PipeModule } from '../../../utils/pipe.module';

@NgModule({
    declarations: [DeceasedCardComponent],
    imports: [CommonModule, RouterModule, PipeModule],
    exports: [DeceasedCardComponent]
})
export class DeceasedCardModule {}
