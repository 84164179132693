import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'lib-service-card',
    templateUrl: './service-card.component.html',
    styleUrls: ['./service-card.component.scss']
})
export class ServiceCardComponent {
    @Input() img: string;
    @Input() title: string;
    @Input() text: string;
    @Input() redirect: string;

    constructor() {}
}
