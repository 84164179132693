import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceCardComponent } from './service-card.component';
import { RouterModule } from '@angular/router';
import { PipeModule } from '../../../utils/pipe.module';

@NgModule({
    declarations: [ServiceCardComponent],
    imports: [CommonModule, RouterModule, PipeModule],
    exports: [ServiceCardComponent]
})
export class ServiceCardModule {}
