import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from '../../services/config.service';
import { HelperService } from '../../services/helper.service';
import { TranslatePipe } from '../../utils/pipes';
import { DefaultService } from 'src/app/utils/api';

declare const grecaptcha: any;

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    FORMS: { firstname: string; lastname: string; phone: string; email: string; message: string } = {
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        message: ''
    };
    phone: string;
    sending: boolean = false;
    validation: any = {};
    send: boolean = false;

    constructor(
        private helperService: HelperService,
        private translatePipe: TranslatePipe,
        private DefaultService: DefaultService,
        private ConfigService: ConfigService,
        private router: Router,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {
        this.phone = this.helperService.getPhoneNumber();
    }

    submit() {
        let that = this;
        that.sending = true;
        let config = that.ConfigService.getConfig();
        grecaptcha.ready(function () {
            grecaptcha.execute(config.captcha_public_key, { action: 'submit' }).then(function (token) {
                that.ConfigService.setConfig({ ...config, token: token });
                that.DefaultService.contactNew(that.FORMS).subscribe({
                    next: (next) => {
                        that.FORMS = {
                            firstname: '',
                            lastname: '',
                            phone: '',
                            email: '',
                            message: ''
                        };
                        that.validation = {};
                        setTimeout(() => {
                            that.sending = false;
                            that.send = true;
                        }, 400);
                    },
                    error: (error) => {
                        that.validation = {
                            ...error.error.details
                        };
                        that.sending = false;
                    }
                });
            });
        });
    }
}
