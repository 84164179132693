import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
    phoneNumber = "";
    externeLink: string = "";

    constructor() { }

    public getPhoneNumber(){
        return this.phoneNumber;
    }
    public setPhoneNumber(number: string){
        this.phoneNumber = number;
    }

    public getExterneLink(){
        return this.externeLink;
    }
    public setExterneLink(externeLink: string){
        this.externeLink = externeLink;
    }
}
