import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldSingleCheckComponent } from './field-single-check.component';



@NgModule({
  declarations: [FieldSingleCheckComponent],
  imports: [
    CommonModule
  ],
  exports: [
    FieldSingleCheckComponent
  ]
})
export class FieldSingleCheckModule { }
