<div class="container">
    <div class="privacy-wrap">
        <div class="page-title">
            {{'cookie-policy-title' | translate}}
        </div>
        <div class="line"></div>
        <div class="text">
            <p>
                Wij hechten veel belang aan de veiligheid en vertrouwelijkheid van uw persoonlijke gegevens die wij verzamelen en gebruiken. Dit cookiebeleid informeert u over het gebruik van cookies. Dit cookiebeleid is voor het laatst bijgewerkt op 2 september 2022.
            </p>

            <p>
                Door dit platform te blijven gebruiken nadat u op de hoogte bent gebracht van ons gebruik van cookies, stemt u ermee in dat u alle informatie over dergelijk gebruik hebt gelezen.
            </p>

            <h3>
                1.	Wie zijn wij?
            </h3>
            <p>
                1.1. Wij" in dit cookiebeleid verwijst naar Ginkgho:
            </p>
            <p>
                Naam: Ginkgho Funerals nv<br>
                Adres: Philipssite 5/1, 3001 Leuven - Belgium<br>
                Bedrijfsnummer: BE 0819.998.408<br>
                E-mail: <a [href]="'mailto:' + email">{{email}}</a><br>
            </p>
            <p>
                1.2.	Wij zijn verantwoordelijk voor het verzamelen en gebruiken van uw persoonlijke gegevens op de manier zoals uitgelegd in dit cookiebeleid. Als u hierover vragen hebt, kunt u contact met ons opnemen via e-mail (<a [href]="'mailto:' + email">{{email}}</a>).
            </p>
            <p>
                1.3.	In bepaalde omstandigheden kunnen (ook) derden verantwoordelijk zijn voor de verwerking van uw persoonsgegevens, bijvoorbeeld als u op een link klikt en ons platform verlaat. In dat geval raden wij u aan het privacybeleid van deze derden te raadplegen.
            </p>
            <h3>
                2.	Wat zijn cookies?
            </h3>
            <p>
                2.1.	Cookies zijn kleine tekstbestanden die op uw apparaat worden opgeslagen en die bepaalde informatie of persoonsgegevens bevatten. Meer informatie over cookies vindt u op <a href="https://www.allaboutcookies.org" target="_blank">www.allaboutcookies.org.</a>
            </p>
            <h3>
                3.	Welke soorten cookies onderscheiden wij?
            </h3>
            <p>
                3.1.	Wij delen cookies in op basis van hun herkomst, doel en levensduur. Hieronder leggen wij uit wat dit betekent.
            </p>
            <p>
                3.2.	Herkomst: wij onderscheiden cookies naargelang wie cookies plaatst.
            </p>
            <div>
                <ul>
                    <li>
                        Cookies van de eerste partij worden door ons geplaatst en beheerd; en   
                    </li>
                    <li>
                        Cookies van derden worden geplaatst en beheerd door een derde partij.
                    </li>
                </ul>
            </div>
            <p>
                3.3.	Doel: wij onderscheiden verschillende cookies naar gelang van hun doel.
            </p>
            <div>
                <ul>
                    <li>
                        Strikt noodzakelijke cookies zijn essentieel om ons platform of onze app te kunnen gebruiken;   
                    </li>
                    <li>
                        Functionele cookies verzamelen informatie over uw voorkeuren om de werking van ons platform of onze app voor u te verbeteren;
                    </li>
                    <li>
                        Analytische cookies verzamelen informatie over het gebruik van ons platform of onze app om ons platform of onze app te verbeteren ten voordele van alle bezoekers of gebruikers; en
                    </li>
                    <li>
                        Marketing cookies verzamelen informatie over uw surfgedrag om inhoud en advertenties relevanter voor u te maken.
                    </li>                    
                </ul>
            </div>
            <p>
                3.4.	Levensduur: wij maken een onderscheid tussen cookies naargelang de duur van hun opslag.
            </p>
            <div>
                <ul>
                    <li>
                        Sessiecookies worden tijdens de sessie in uw browser of app opgeslagen en worden verwijderd wanneer u uw browser of app afsluit; en
                    </li>
                    <li>
                        Persistente cookies worden langer dan de sessie bewaard en worden pas verwijderd wanneer zij hun vervaldatum hebben bereikt, een nieuwe versie van de cookie wordt geplaatst of de cookie handmatig wordt verwijderd.
                    </li>
                </ul>
            </div>
            <h3>
                4.	Welke cookies gebruiken wij?
            </h3>
            <p>
                4.1.	Wanneer u ons platform bezoekt, worden alleen strikt noodzakelijke cookies op uw apparaat geplaatst.
            </p>
            <p>
                4.2.	Hieronder geven wij u een overzicht van de cookies die wij plaatsen of gebruiken.
            </p>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <th>Naam</th>
                            <th>Oorsprong</th>
                            <th>Doel</th>
                            <th>Levensduur</th>
                        </tr>
                        <tr>
                            <td>_ga</td>
                            <td>Derde partij</td>
                            <td>Analytische cookie</td>
                            <td>2 jaar</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h3>
                5.	Met wie delen wij uw persoonsgegevens?
            </h3>
            <p>
                5.1.	In principe delen wij uw persoonsgegevens met niemand anders dan de personen die voor ons werken, en met de leveranciers die ons helpen bij de verwerking van uw persoonsgegevens. Iedereen die toegang heeft tot uw persoonsgegevens is te allen tijde gebonden aan strikte wettelijke of contractuele verplichtingen om uw persoonsgegevens veilig en vertrouwelijk te behandelen. Dit betekent dat alleen de volgende categorieën ontvangers uw persoonsgegevens zullen ontvangen:
            </p>
            <div>
                <ul>
                    <li>
                        Jij;
                    </li>
                    <li>
                        Uw werkgever of zakenpartners, maar alleen wanneer dit noodzakelijk is voor de hierboven vermelde doeleinden (bv. wanneer uw werkgever onze leverancier of klant is);
                    </li>
                    <li>
                        Onze werknemers en leveranciers; en
                    </li>
                    <li>
                        Overheidsinstanties of gerechtelijke autoriteiten, voor zover wij verplicht zijn uw persoonsgegevens met hen te delen (bijv. belastingdiensten, politie of gerechtelijke autoriteiten).
                    </li>
                </ul>
            </div>
            <p>
                5.2.	Wij geven uw persoonsgegevens niet door buiten de Europese Economische Ruimte (EER) (de Europese Economische Ruimte bestaat uit de EU, Liechtenstein, Noorwegen en IJsland). Wij zullen uw persoonsgegevens alleen buiten de EER doorgeven indien u of uw werkgever, als klant of leverancier, kantoren buiten de EER heeft waarmee wij moeten communiceren. Indien een doorgifte zou plaatsvinden, zullen wij voldoende waarborgen nemen om uw persoonsgegevens tijdens de doorgifte te beschermen (bijvoorbeeld door een overeenkomst te sluiten op basis van standaardclausules voor gegevensbescherming die door de Europese Commissie zijn goedgekeurd).
            </p>
            <h3>
                6.	Hoe lang bewaren wij uw persoonsgegevens?
            </h3>
            <p>
                6.1.	Uw persoonsgegevens worden alleen verwerkt zolang als nodig is om de hierboven beschreven doeleinden te bereiken of, wanneer wij u om toestemming hebben gevraagd, totdat u uw toestemming intrekt. Als algemene regel geldt dat sessiecookies worden verwijderd wanneer u uw browser of app afsluit en dat persistente cookies alleen worden verwijderd wanneer ze hun vervaldatum hebben bereikt, een nieuwe versie van de cookie wordt geplaatst of de cookie handmatig wordt verwijderd.
            </p>
            <h3>
                7.	Wat als je geen cookies wilt? 
            </h3>
            <p>
                7.1.	Browserinstellingen: u kunt uw browserinstellingen of apparaatinstellingen aanpassen om te voorkomen dat er cookies op uw apparaat worden geplaatst. Hoe u deze instellingen aanpast en of u toestemming geeft voor het gebruik van cookies is afhankelijk van de browser die u gebruikt. Klik hieronder op de browser die u gebruikt om meer te weten te komen over hoe u de cookie-instellingen moet aanpassen.
            </p>
            <div>
                <ul>
                    <li>
                        <a href="https://support.microsoft.com/nl-be/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">
                            Internet Explorer
                        </a>
                    </li>
                    <li>
                        <a href="https://support.mozilla.org/nl/kb/cookies-verwijderen-gegevens-wissen-websites-opgeslagen" target="_blank">
                            Mozilla Firefox
                        </a>
                    </li>
                    <li>
                        <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=nl" target="_blank" >
                            Chrome
                        </a>
                    </li>
                    <li>
                        <a href="https://support.apple.com/en-us/HT201265" target="_blank">
                            Safari (iOS)                            
                        </a>
                    </li>
                    <li>
                        <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank">
                            Apple Safari (MacOS)
                        </a>
                    </li>
                </ul>
            </div>
            <p>
                7.2.	Verwijderen: u kunt geplaatste cookies handmatig van uw apparaat verwijderen. Ga hiervoor naar uw browserinstellingen of apparaatinstellingen.
            </p>
            <p>
                7.3.	Let op: het wijzigen van de browserinstellingen of het verwijderen van cookies kan tot gevolg hebben dat bepaalde onderdelen van ons platform niet meer naar behoren werken. Houd er ook rekening mee dat u mogelijk de browserinstellingen van meerdere browsers moet wijzigen als u verschillende browsers gebruikt.
            </p>
            <h3>
                8.	Uw rechten met betrekking tot uw persoonsgegevens
            </h3>
            <p>
                8.1.	U heeft bepaalde rechten met betrekking tot de verwerking van uw persoonsgegevens: het recht op toegang, rectificatie, wissen en dataportabiliteit, evenals het recht om bezwaar te maken tegen de verwerking van uw persoonsgegevens of om deze te beperken en om uw toestemming in te trekken. Om uw toestemming voor het plaatsen van cookies in te trekken, verwijzen wij u naar artikel 7. Om een van uw rechten uit te oefenen, kunt u een schriftelijk verzoek indienen bij <a [href]="'mailto:' + email">{{email}}</a> onder vermelding van het recht waarop uw verzoek betrekking heeft. Als u nog steeds ontevreden bent, hebt u het recht om contact op te nemen met de bevoegde gegevensbeschermingsautoriteit, d.w.z. de Belgische toezichthoudende autoriteit (www.gegevensbeschermingsautoriteit.be).
            </p>
            <h3>
                9.	Wijzigingen in dit cookiebeleid
            </h3>
            <p>
                9.1.	Wij behouden ons het recht voor om dit cookiebeleid op eigen initiatief te wijzigen. Indien materiële wijzigingen in dit cookiebeleid van invloed kunnen zijn op de verwerking van uw persoonsgegevens of uw eerder gegeven toestemming, zullen wij u opnieuw om toestemming vragen wanneer u ons platform of onze app bezoekt of gebruikt en zullen wij deze wijzigingen aan u communiceren.
            </p>
            <p>
                9.2.	Wij nodigen u uit om de laatste versie van dit cookiebeleid online te raadplegen. Ons cookiebeleid vermeldt de datum waarop ons cookiebeleid voor het laatst werd gewijzigd.
            </p>
            <h3>
                10.	Hoe kunt u contact met ons opnemen?
            </h3>
            <p>
                10.1.	Indien u nog vragen heeft over de verwerking van uw persoonsgegevens , aarzel dan niet om contact op te nemen met onze privacy manager. U kunt contact opnemen met onze privacy manager via e-mail: <a [href]="'mailto:' + email">{{email}}</a>
            </p>
        </div>
    </div>
</div>
