import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    modalRoute$ = new BehaviorSubject<boolean>(false);
    hideNavigation$ = new BehaviorSubject<boolean>(false);
    hideFooter$ = new BehaviorSubject<boolean>(false);
    hideCaptcha$ = new BehaviorSubject<boolean>(false);
    navCollapsed$ = new BehaviorSubject<boolean>(false);

    private config: any = {
        captcha_public_key: '6Ld4yD0hAAAAAH7qE3tQ3aNGqLb-_wfbB4dZycEw',
        token: ''
    };

    constructor(private TitleService: Title, private Router: Router, public Route: ActivatedRoute) {}

    public getConfig(): any {
        return this.config;
    }

    public setConfig(config): any {
        this.config = { ...this.config, ...config };
    }

    // modal routes
    public setModalRoute(value: boolean) {
        this.modalRoute$.next(value);
    }
    public getModalRoute() {
        return this.modalRoute$;
    }

    // hide navigation
    public setHideNavigation(value: boolean) {
        this.hideNavigation$.next(value);
    }
    public getHideNavigation() {
        return this.hideNavigation$;
    }

    // hide footer
    public setHideFooter(value: boolean) {
        this.hideFooter$.next(value);
    }
    public getHideFooter() {
        return this.hideFooter$;
    }

    // hide footer
    public setHideCaptcha(value: boolean) {
        this.hideCaptcha$.next(value);
    }
    public getHideCaptcha() {
        return this.hideCaptcha$;
    }

    // nav collapsed
    public setNavCollapsed(value: boolean) {
        this.navCollapsed$.next(value);
    }
    public getNavCollapsed() {
        return this.navCollapsed$;
    }
}
