<div class="page">
    <div>
        <div class="section-margin--big">
            <div id="wachtdienst" class="title">
                {{'wait-service-subtitle' | translate}}
            </div>
            <p class="text">
                {{'wait-service-text' | translate}}
            </p>
        </div>
        <div class="section-margin--medium">
            <div class="title" id="complete_uitvaartzorg">
                {{'complete-funeral-care-subtitle' | translate}}
            </div>
            <p class="text">
                {{'complete-funeral-care-text' | translate}}
            </p>
        </div>
        <div>
            <!-- <img src="assets/shared/img/misc/white_flower.png" alt="witte bloem"> -->
            <img [src]="config.deceased.care" alt="Complete uitvaartzorg">
        </div>
    </div>
    <div>
        <div class="section-margin--medium">
            <!-- <img src="assets/shared/img/misc/office.png" alt="kantoor"> -->
            <img [src]="config.deceased.opbaring" alt="Opbaring">
        </div>
        <div class="section-margin--big">
            <div class="title" id="opbaring">
                {{'wake-subtitle' | translate}}
            </div>
            <p class="text">
                {{'wake-text' | translate}}
            </p>
        </div>
        <div>
            <div class="title" id="vervoer">
                {{'transport-subtitle' | translate}}
            </div>
            <p class="text">
                {{'transport-text' | translate}}
            </p>
        </div>
    </div>
</div>