import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationComponent } from './location.component';
import { PipeModule } from '../../../utils/pipe.module';

@NgModule({
    declarations: [LocationComponent],
    imports: [CommonModule, PipeModule],
    exports: [LocationComponent]
})
export class LocationModule {}
