<div [class.deceased-wrap--small]="size === 'small'" [class.deceased-wrap--big]="size === 'big'">

    <div class="image-wrap">
        <ng-container *ngIf="deceased.imageUrl === ''">
            <img [class.img--small]="size === 'small'" [class.img--big]="size === 'big'" src="assets/shared/img/logo/deceased_placeholder.svg" alt="lege placeholder">
        </ng-container>
        <ng-container *ngIf="deceased.imageUrl !== ''">
            <img [class.img--small]="size === 'small'" [class.img--big]="size === 'big'" [src]="deceased.imageUrl" alt="{{deceased.firstname}} {{deceased.lastname}}">
        </ng-container>
    </div>
    <!-- assets/img/misc/deceased-placeholder.svg -->
    <div>
        <div [class.full-name-wrap--small]="size === 'small'" [class.center]="size === 'big'" [class.full-name-wrap--big]="size === 'big'">
            <a [href]="deceased.pageUrl" target="_blank" class="full-name hover-link">
                {{deceased.surname || deceased.firstname}}
                {{deceased.lastname}}
            </a>
        </div>
        <div class="info" [class.info--small]="size === 'small'">
            <div [class.center]="size === 'big'">
                {{deceased.deathDate | formatTs: 'monthDayShorthand'}}
            </div>
            <div *ngIf="size === 'small'">
                <ng-container> - </ng-container>
            </div>
            <div [class.center]="size === 'big'">
                {{deceased.city}}
            </div>
        </div>
    </div>
</div>