<div class="page">
    <div>
        <div class="section-margin--medium">
            <div class="title" id="livestream">
                {{'livestream-subtitle' | translate}}
            </div>
            <div class="text">
                <p>
                    {{'livestream-text' | translate}} 
                </p>
            </div>
        </div>
        <div class="section-margin--big">
            <div>
                <img [src]="config.duringFuneral.livestreamImage" alt="laatste groet kamer">
            </div>
        </div>
        <div class="title" id="ceremonie">
            {{'ceremony-subtitle' | translate}}
        </div>
        <div class="text">
            <p class="extra-whitespace">
                {{'ceremony-text1' | translate}}
                
            </p>
            <p>
                {{'ceremony-text2' | translate}}
            </p>
        </div>
    </div>
    <div>
        <div class="section-margin--medium">
            <div class="title" id="koffietafel_rouwmaaltijd">
                {{'coffee-table-subtitle' | translate}}
            </div>
            <div class="text">
                <p>
                    {{'coffee-table-text' | translate}}
                </p>
            </div>
        </div>
        <div>
            <img [src]="config.duringFuneral.koffietafelImage" alt="koffietafel">
        </div>
    </div>
</div>