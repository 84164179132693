import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'lib-location',
    templateUrl: './location.component.html',
    styleUrls: ['./location.component.scss']
})
export class LocationComponent {
    @Input() img: string = '';
    @Input() type: string = '';
    @Input() address: string = '';
    @Input() town: string = '';
    @Input() phone: string = '';
    @Input() email: string = '';
    @Input() singleLocation: boolean = false;

    constructor() {}
}
