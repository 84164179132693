import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ServicesComponent } from './pages/services/services.component';
import { HomeComponent } from './pages/home/home.component';
import { ServiceDetailsComponent } from './pages/service-details/service-details.component';
import { DeceasedComponent } from './views/deceased/deceased.component';
import { DuringFuneralComponent } from './views/during-funeral/during-funeral.component';
import { ArrangementComponent } from './views/arrangement/arrangement.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SearchDeceasedComponent } from './pages/search-deceased/search-deceased.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsComponent } from './pages/terms/terms.component';
// import { emailObfuscationPipe, TranslatePipe } from './utils/pipes';
import { ToastrModule } from 'ngx-toastr';
import { emailObfuscationPipe, SafePipe, TranslatePipe } from './utils/pipes';
import { InitService } from './services/init.service';
import { FooterModule } from './components/layout/footer/footer.module';
import { HeaderModule } from './components/layout/header/header.module';
import { ServiceCardModule } from './components/misc/service-card/service-card.module';
import { ServiceCardLinkModule } from './components/layout/service-card-link/service-card-link.module';
import { FieldTextModule } from './components/fields/field-text/field-text.module';
import { FieldTextareaModule } from './components/fields/field-textarea/field-textarea.module';
import { PipeModule } from './utils/pipe.module';
import { MonitoringService } from './services/monitoring.service';
import { ApiModule, Configuration } from './utils/api';
import { NavigationModule } from './components/layout/navigation/navigation.module';
import { ButtonModule } from './components/common/button/button.module';
import { FieldSingleCheckModule } from './components/fields/field-single-check/field-single-check.module';
import { DeceasedCardModule } from './components/misc/deceased-card/deceased-card.module';
import { LocationModule } from './components/misc/location/location.module';
import { SpinnerModule } from './components/misc/spinner/spinner.module';
import { TranslationsModule } from './components/misc/translations/translations.module';
import { UserCardModule } from './components/misc/user-card/user-card.module';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { CookiePopupComponent } from './components/misc/cookie-popup/cookie-popup.component';
import { SingleViewServiceComponent } from './views/single-view-service/single-view-service.component';

@NgModule({
    declarations: [
        AppComponent,
        ContactComponent,
        AboutUsComponent,
        ServicesComponent,
        HomeComponent,
        ServiceDetailsComponent,
        DeceasedComponent,
        DuringFuneralComponent,
        ArrangementComponent,
        SearchDeceasedComponent,
        PrivacyPolicyComponent,
        TermsComponent,
        CookiePopupComponent,
        CookiePolicyComponent,
        SafePipe,
        SingleViewServiceComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        RouterModule,
        FooterModule,
        ButtonModule,
        PipeModule,
        HeaderModule,
        ServiceCardModule,
        ServiceCardLinkModule,
        SpinnerModule,
        TranslationsModule,
        UserCardModule,
        LocationModule,
        DeceasedCardModule,
        NavigationModule,
        FieldTextModule,
        FieldTextareaModule,
        FieldSingleCheckModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            extendedTimeOut: 5000,
            positionClass: 'toast-bottom-right',
            closeButton: true,
            // disableTimeOut: true,
            iconClasses: {
                error: 'toast-error',
                info: 'toast-info',
                success: 'toast-success',
                warning: 'toast-warning'
            }
        }),
        ApiModule.forRoot(() => {
            return new Configuration({ basePath: '/api' });
        })
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: initializeApp, multi: true, deps: [InitService] },
        TranslatePipe,
        MonitoringService,
        emailObfuscationPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
export function initializeApp(InitService: InitService) {
    return (): Promise<any> => {
        return InitService.initialize();
    };
}
