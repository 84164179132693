import { Component, Input, OnInit } from '@angular/core';
import { Deceaseds } from '../../models/deceased';

@Component({
  selector: 'lib-deceased-card',
  templateUrl: './deceased-card.component.html',
  styleUrls: ['./deceased-card.component.scss']
})
export class DeceasedCardComponent implements OnInit {
    @Input() deceased: any;
    @Input() size: string;
  constructor() { }

  ngOnInit(): void {

  }

}
