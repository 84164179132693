import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldTextComponent } from './field-text.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    FieldTextComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports:[
    FieldTextComponent
  ]
})
export class FieldTextModule { }
