<ng-container *ngIf="!ready && !disabled">
    <lib-spinner [centerVertically]="true"></lib-spinner>
</ng-container>
<ng-container *ngIf="ready && !disabled">
    <div class="translations-filters" id="top" [class.no-pointer]="saving?.length">
        <div class="row">
            <div class="col-3">
                <lib-field-text
                    [model]="FILTERS.term" 
                    (modelChange)="FILTERS.term = $event;" 
                    [label]="''"
                    [instant]="true"
                    [typeClass]="'type-search'"
                    [placeholder]="'filter code...'">
                </lib-field-text>
            </div>
            <div class="col-9 d-flex align-items-end">
                <lib-field-single-check
                    [model]="FILTERS.empty" 
                    (modelChange)="FILTERS.empty = $event;" 
                    [title]="'all languages empty (new records)'">
                </lib-field-single-check>
            </div>
        </div>
    </div>
    <table class="translations-table">
        <tr>
            <th style="min-width: 300px; max-width: 300px;">code</th>
            <th style="min-width: 120px; max-width: 120px;">portal</th>
            <th [width]="thWidth" *ngFor="let lang of languages">{{lang}}</th>
        </tr>
        <tr *ngFor="let string of strings | callback: filterStrings; index as $i" [class.saving]="indexIsSaving($i)" class="saving--pre">
            <td style="min-width: 300px; max-width: 300px;">
                <div class="code" [title]="string.string">{{string.string}}</div>
            </td>
            <td style="min-width: 120px; max-width: 120px;">
                <div class="code" [title]="string.portal">{{string.portal}}</div>
            </td>
            <td *ngFor="let lang of languages">
                <input type="text" [(ngModel)]="string[lang]" [class.translation-empty]="!string[lang]" [class.translation-filled]="string[lang]" (keydown.enter)="$event.target.blur()" (blur)="indexIsChanged($i) && saveIndex($i)" (ngModelChange)="changeIndex($i)">
            </td>
        </tr>
    </table>

    <div (click)="toTop()" id="up" [class.shown]="helper.showUp">UP</div>
</ng-container>

<ng-container *ngIf="disabled">
    <div class="error">
        <div class="spacer"></div>
        <main class="d-flex justify-content-center">
            <section>
                <h1>Temporarily unavailable</h1>
                <h2>The translations page is temporarily unavailable</h2>
            </section>
        </main>
    </div>
</ng-container>

