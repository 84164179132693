<div class="page">
    <ng-container *ngFor="let partial of config[routeDataType].partial">
            <div>
            <ng-container *ngFor="let section of partial.section">
                <div [class.section-margin--medium]="section.items[0].type === 'header'" [class.section-margin--big]="section.items[0].type === 'image'">
                    <ng-container *ngFor="let item of section.items; let last = last">
                        <ng-container *ngIf="item.type === 'header'">
                            <div class="title" id="{{section.id}}">
                                {{item.text}}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="item.type === 'paragraph'">
                            <p class="text" [class.extra-whitespace]="!last" [innerHTML]="item.text | safe: 'html'"></p>
                        </ng-container>
                        <ng-container *ngIf="item.type === 'image'">
                            <div>
                                <img [src]="item.src" [alt]="item.alt">
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>