import { Injectable } from '@angular/core';
import { Observable, forkJoin, BehaviorSubject, of } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { MonitoringService } from '../services/monitoring.service';
import { ConfigService } from '../services/config.service';
import { DefaultService } from 'src/app/utils/api';
import { TranslatorService } from './translator.service';
// import { HelpersService } from './helpers.service';

declare const grecaptcha: any;

@Injectable({
    providedIn: 'root'
})
export class InitService {
    ready$ = new BehaviorSubject<boolean>(false);
    constructor(
        private DefaultService: DefaultService,
        private ConfigService: ConfigService,
        private MonitoringService: MonitoringService,
        private TranslatorService: TranslatorService // private HelperService: HelpersService
    ) {}

    initialize() {
        let that = this;
        let config = that.ConfigService.getConfig();
        return new Promise<void>((resolve, reject) => {
            that.DefaultService.configGetConfig().subscribe(async (next: any) => {
                that.ConfigService.setConfig({
                    ...next,
                    node_env: next.node_env,
                    instrumentationkey: next.instrumentationkey,
                    enableStringUpdate: next.enableStringUpdate
                });
                that.MonitoringService.init();
                const observables$: Observable<any>[] = [of(null)];
                observables$.push(await that.TranslatorService.init());
                forkJoin(observables$).subscribe((next) => {
                    resolve();
                });
            });
        });
    }
}
