import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';

@Component({
    selector: 'app-deceased',
    templateUrl: './deceased.component.html',
    styleUrls: ['./deceased.component.scss']
})
export class DeceasedComponent implements OnInit {
    config: any = {};
    constructor(private ConfigService: ConfigService) {}

    ngOnInit(): void {
        this.config = this.ConfigService.getConfig();
    }
}
