<div class="about-us-wrap">
    <img [src]="img" [alt]="'img_' + name">
    <div class="about-user-info">
        <div class="about-user-name">{{name}}</div>
        <div class="about-user-wrap about-user-text">{{text}}</div>
        <ng-container *ngIf="phone">
            <div class="about-user-wrap about-user--blue about-user-icon"><i class="icon icon-phone"></i><a class="telephone-link hover-link" href="tel:{{phone}}">{{phone}}</a></div>
        </ng-container>
        <div class="about-user-wrap about-user--blue about-user-icon"><i class="icon icon-mail"></i><a class="hover-link" [href]="'mailto:' + email">{{email}}</a></div>
    </div>
</div>