<div class="service-wrap">
    <div class="service-title">
        <a class="service-title-link" [routerLink]="info.routerLink">
            {{info.title}}
        </a>
    </div>
    <div *ngFor="let link of info.links;">
        <a class="hover-link" [routerLink]="info.routerLink" fragment="{{link.anchor}}">
            {{link.title}}
        </a>
    </div>
    
</div>