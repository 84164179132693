import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslationsComponent } from './components/misc/translations/translations.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { HomeComponent } from './pages/home/home.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { SearchDeceasedComponent } from './pages/search-deceased/search-deceased.component';
import { ServiceDetailsComponent } from './pages/service-details/service-details.component';
import { ServicesComponent } from './pages/services/services.component';
import { TermsComponent } from './pages/terms/terms.component';
import { ArrangementComponent } from './views/arrangement/arrangement.component';
import { DeceasedComponent } from './views/deceased/deceased.component';
import { DuringFuneralComponent } from './views/during-funeral/during-funeral.component';
import { SingleViewServiceComponent } from './views/single-view-service/single-view-service.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        component: HomeComponent,
        data: {
            hideCaptcha: true,
            title: 'home'
        }
    },
    {
        path: 'contact',
        component: ContactComponent,
        data: {
            title: 'contact'
        }
    },
    {
        path: 'over-ons',
        component: AboutUsComponent,
        data: {
            hideCaptcha: true,
            title: 'about_us'
        }
    },
    {
        path: 'diensten',
        component: ServicesComponent,
        data: {
            hideCaptcha: true,
            title: 'services'
        }
    },
    {
        path: 'overlijdens',
        component: SearchDeceasedComponent,
        data: {
            hideCaptcha: true,
            title: 'deceaseds'
        }
    },
    {
        path: 'cookiebeleid',
        component: CookiePolicyComponent,
        data: {
            hideCaptcha: true,
            title: 'cookiebeleid'
        }
    },
    {
        path: 'diensten',
        component: ServiceDetailsComponent,
        data: { hideCaptcha: true },
        children: [
            {
                path: 'bij-overlijden',
                component: DeceasedComponent,
                data: { headerTitle: 'Bij overlijden', hideCaptcha: true, title: 'by-deceased' }
            },
            {
                path: 'voor-de-uitvaart',
                component: SingleViewServiceComponent,
                data: {
                    headerTitle: 'Voor de uitvaart',
                    hideCaptcha: true,
                    title: 'before-funeral',
                    dataType: 'beforeFuneral'
                }
            },
            {
                path: 'tijdens-de-uitvaart',
                component: DuringFuneralComponent,
                data: { headerTitle: 'Tijdens de uitvaart', hideCaptcha: true, title: 'during-funeral' }
            },
            {
                path: 'na-de-uitvaart',
                component: SingleViewServiceComponent,
                data: {
                    headerTitle: 'Na de uitvaart',
                    hideCaptcha: true,
                    title: 'after-funeral',
                    dataType: 'afterFuneral'
                }
            },
            {
                path: 'voorafregeling',
                component: ArrangementComponent,
                data: { headerTitle: 'Voorafregeling', hideCaptcha: true, title: 'arrangement' }
            }
        ]
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        data: {
            hideCaptcha: true,
            title: 'privacy-policy'
        }
    },
    {
        path: 'terms',
        component: TermsComponent,
        data: {
            hideCaptcha: true,
            title: 'terms'
        }
    },
    {
        path: 'translations',
        component: TranslationsComponent,
        data: { title: '', hideNavigation: true, hideCaptcha: true, hideFooter: true, noIndex: true }
    },
    {
        path: '**',
        redirectTo: 'home'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
