<div class="cookies-popup-wrap" *ngIf="!hidden" [class.dismissed]="status">
    <div class="inner">
        <div class="title">{{'cookies_title' | translate}}</div>
        <div class="descr">
            {{'cookies_descr' | translate}}<a [routerLink]="['/cookiebeleid']" target="_blank">{{'cookies_descr_2' | translate}}</a>{{'cookies_descr_3' | translate}}</div>
        <div class="buttons">
            <button (click)="accept()" class="accept">{{'cookies_accept_btn' | translate}}</button>
            <button (click)="decline()" class="decline">{{'cookies_decline_btn' | translate}}</button>
        </div>
    </div>
    <div class="section-sep"></div>
</div>