<div class="page">
    <div>
        <div class="section-margin--big">
            <div class="title" id="wilsbeschikking">
                {{'last-will-subtitle' | translate}}
            </div>
            <div class="text content" [innerHtml]="'lastwill_content' | translate | safe: 'html'">
                <!-- <p class="extra-whitespace">
                    {{'last-will-text1' | translate}}
                </p>
                <p class="extra-whitespace">
                    {{'last-will-text2' | translate}}
                </p>
                <p>
                    {{'last-will-text3' | translate}}
                    <strong>{{'last-will-text4' | translate}}</strong>
                    {{'last-will-text5' | translate}}
                </p> -->
            </div>
        </div>
        <div>
            <div>
                <img src="assets/shared/img/misc/hand_in_hand.png" alt="hand in hand">
            </div>
        </div>
    </div>
    <div>
        <div class="section-margin--medium">
            <img [src]="config.arrangement.willImage" alt="wilsbeschikking">
        </div>
        <div>
            <div class="title" id="uitvaartverzekering">
                {{'funeral-assurance-subtitle' | translate}}
            </div>
            <div class="text" [innerHtml]="'insurance_content' | translate | safe: 'html'">
                <!-- <p class="extra-whitespace">
                    {{'funeral-assurance-text1' | translate}}
                </p>
                <p>
                    {{'funeral-assurance-text2' | translate}}
                </p> -->
            </div>
        </div>
    </div>
</div>