import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ConfigService } from './config.service';

@Injectable()
export class MonitoringService {
    appInsights: ApplicationInsights;
    constructor(private ConfigService: ConfigService) { }

    init() {
        const instrumentationkey = this.ConfigService.getConfig()?.instrumentationkey;
        if (!instrumentationkey) return;
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationkey
            }
        });
        this.appInsights.loadAppInsights();
    }

    logPageView(name?: string, url?: string) {
        // option to call manually
        this.appInsights?.trackPageView({
            name: name,
            uri: url
        });
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        this.appInsights?.trackEvent({ name: name }, properties);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        this.appInsights?.trackMetric({ name: name, average: average }, properties);
    }

    logException(exception: Error, severityLevel?: number) {
        this.appInsights?.trackException({
            exception: exception,
            severityLevel: severityLevel,
            properties: { location: window.location }
        });
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        this.appInsights?.trackTrace({ message: message }, properties);
    }
}
