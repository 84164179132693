<div class="header-bg-image {{classes}}" [style.background-position-y]="bgImagePosition + '%'" [style.background-image]="'linear-gradient(90.1deg, rgba(0, 0, 0, 0.1) 0.09%, rgba(0, 0, 0, 0.05) 48.23%, rgba(0, 0, 0, 0.05) 48.24%, rgba(0, 0, 0, 0) 99.5%),url('+bgImage+')'">
    <div class="container">
        <header>
            <div class="header-title" [class.company-title]="image">
                <h1>
                    {{title}}
                    <ng-container *ngIf="image">
                        <img [src]="image" [alt]="'image_' + title">
                    </ng-container>
                </h1>
            </div>
            <div class="header-text" [innerHTML]="text">
                <!-- {{text}} -->
            </div>
            <div class="header-contact">
                <div class="button-wrap" *ngIf="displayContactButton">
                    <lib-button [text]="'Contacteer ons'" [linkHref]="['/contact']" [classes]="'header-button'"></lib-button>
                </div>
                <div *ngIf="displayphone">
                    <a class="telephone-link" href="tel:{{phone}}">
                        {{phone}}
                    </a>
                </div>
                <div *ngIf="displaySearchBar" class="search-wrap">
                    <lib-field-text (enter)="search($event)" [search]="true" [showIcon]="true" [model]="inputModel" [placeholder]="'Zoeken'"></lib-field-text>
                </div>
            </div>
        </header>    
    </div>
</div>
