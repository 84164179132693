import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationsComponent } from './translations.component';
import { callbackPipe, TranslatePipe } from '../../../utils/pipes';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    TranslationsComponent,
    callbackPipe
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    TranslationsComponent
  ]
})
export class TranslationsModule { }
