<lib-header [bgImagePosition]="backgroundPositioning" [bgImage]="aboutUsImage" classes="about-us-header" [displayphone]="true" [title]="'about_us' | translate" [text]="'header-text' | translate" [phone]="phone"></lib-header>
<div class="container">
    <div class="us-wrap">
        <h1 class="title page-title">{{'about_us_title' | translate }}</h1>
        <div class="line"></div>
        <div class="company-info">
            {{aboutUsText}}
        </div>
        <div class="full-card-wrap">
            <ng-container *ngFor="let user of users">
                <lib-user-card [img]="user.img" [name]="user.name" [text]="user.text" [phone]="user.phone" [email]="user.email"></lib-user-card>
            </ng-container>
        </div>
    </div>
    
    <div class="history-wrap">
        <h1 class="title page-title">{{'history_title' | translate }}</h1>
        <div class="line"></div>
        <ng-container *ngFor="let historyItem of history">
            <div class="date">{{historyItem.title}}</div>
            <div class="text-of-date" [innerHtml]="historyItem.text | safe: 'html'"></div>
        </ng-container>
    </div>
</div>