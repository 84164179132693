import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

  @Component({
    selector: 'lib-field-text',
    templateUrl: './field-text.component.html',
    styleUrls: ['./field-text.component.scss']
  })
  export class FieldTextComponent implements OnChanges {
      @Input() showIcon?: boolean = false;
      @Input() search: boolean = false;
      @Input() label?: string;
      @Input() error?: string;
      @Input() errorTooltip?: string;
      @Input() optionalString?: string;
      @Input() afterString?: string;
      @Input() type?: 'integer' | 'float' | 'currency' | 'password' | 'email' | '';
      @Input() placeholder?: string = '';
      @Input() model: string;
      @Input() inputWidth: string;
      @Input() classes: string[] = [];
      @Output() modelChange = new EventEmitter();
      @Output() enter = new EventEmitter();
  
      localModel: any;
      togglePwd: boolean = false;
  
      constructor() {}
  

  
    ngOnChanges(changes: SimpleChanges) {
        this.localModel = this.model;
    }
  
    changes() {
        this.modelChange.emit(this.localModel);
    }
  }
