<lib-header classes="services-header" [bgImage]="config.services.headerImage" [displayphone]="true" [title]="'services' | translate" [text]="'header-text' | translate" [phone]="phone"></lib-header>
<div class="container">
    <div class="services-wrap">
        <div class="text">
            {{'services-title' | translate}}
        </div>
        <div class="service-wrap">
            <div class="subtitle page-title">
                <a [routerLink]="['/diensten/bij-overlijden']">
                    {{'by-decease-title' | translate}}
                </a>
            </div>
            <div class="line"></div>
            <div class="subtitle-text">

            {{'service-by-decease1' | translate}}
                <strong><a class="telephone-link" href="tel:{{phone}}">{{phone}}</a></strong>.
            {{'service-by-decease2' | translate}}
            </div>
            <div class="services-links">
                <a class="subtitle-link hover-link" [routerLink]="['/diensten/bij-overlijden']" fragment="wachtdienst">{{'wait-service-subtitle' | translate}}</a>
                <a class="subtitle-link hover-link" [routerLink]="['/diensten/bij-overlijden']" fragment="complete_uitvaartzorg">{{'complete-funeral-care-subtitle' | translate}}</a>
                <a class="subtitle-link hover-link" [routerLink]="['/diensten/bij-overlijden']" fragment="opbaring">{{'wake-subtitle' | translate}}</a>
                <a class="subtitle-link hover-link" [routerLink]="['/diensten/bij-overlijden']" fragment="vervoer">{{'transport-subtitle' | translate}}</a>
            </div>
        </div>
        <div class="service-wrap">
            <div class="subtitle page-title">
                <a [routerLink]="['/diensten/voor-de-uitvaart']">
                    {{'before-funeral-title' | translate}}
                </a>
            </div>
                <div class="line"></div>
            <div class="subtitle-text">
                {{'before-funeral-text' | translate}}
            </div>
            <div class="services-links">
                <a class="subtitle-link hover-link" [routerLink]="['/diensten/voor-de-uitvaart']" fragment="persoonlijk_gesprek">{{'personal-conversation-subtitle' | translate}}</a>
                <a class="subtitle-link hover-link" [routerLink]="['/diensten/voor-de-uitvaart']" fragment="drukwerk">{{'press-work-subtitle' | translate}}</a>
                <a class="subtitle-link hover-link" [routerLink]="['/diensten/voor-de-uitvaart']" fragment="kranten_digitaal_sociale_media">{{'papers-digital-social-media-subtitle' | translate}}</a>
                <a class="subtitle-link hover-link" [routerLink]="['/diensten/voor-de-uitvaart']" fragment="kist_lijkwade_urne">{{'coffin-urn-subtitle' | translate}}</a>
                <a class="subtitle-link hover-link" [routerLink]="['/diensten/voor-de-uitvaart']" fragment="herinneringsjuwelen_ashouders">{{'juwel-axle-holders-subtitle' | translate}}</a>
            </div>
        </div>
        <div class="service-wrap">
            <div class="subtitle page-title">
                <a [routerLink]="['/diensten/tijdens-de-uitvaart']">
                    {{'during-funeral-title' | translate}}            
                </a>
            </div>
            <div class="line"></div>
            <div class="subtitle-text">
                {{'during-funeral-service-text' | translate}}
            </div>
            <div class="services-links">
                <a class="subtitle-link hover-link" [routerLink]="['/diensten/tijdens-de-uitvaart']" fragment="livestream">{{'livestream-subtitle' | translate}}</a>
                <a class="subtitle-link hover-link" [routerLink]="['/diensten/tijdens-de-uitvaart']" fragment="ceremonie">{{'ceremony-subtitle' | translate}}</a>
                <a class="subtitle-link hover-link" [routerLink]="['/diensten/tijdens-de-uitvaart']" fragment="koffietafel_rouwmaaltijd">{{'coffee-table-subtitle' | translate}}</a>
            </div>
        </div>
        <div class="service-wrap">
            <div class="subtitle page-title">
                <a [routerLink]="['/diensten/na-de-uitvaart']">
                    {{'after-funeral-title' | translate}}
                </a>
            </div>
            <div class="line"></div>
            <div class="subtitle-text">
                {{'after-funeral-service-text' | translate}}
            </div>
            <div class="services-links">
                <a class="subtitle-link hover-link" [routerLink]="['/diensten/na-de-uitvaart']" fragment="administratieve_nazorg">{{'administrative-care-subtitle' | translate}}</a>
                <a class="subtitle-link hover-link" [routerLink]="['/diensten/na-de-uitvaart']" fragment="grafzerken">{{'grave-stones-subtitle' | translate}}</a>
            </div>
        </div>
        <div class="service-wrap">
            <div class="subtitle page-title">
                <a [routerLink]="['/diensten/voorafregeling']">
                    {{'arrangement-title' | translate}}
                </a>
            </div>
            <div class="line"></div>
            <div class="subtitle-text">
                {{'arrangement-service-text' | translate}}
            </div>
            <div class="services-links">
                <a class="subtitle-link hover-link" [routerLink]="['/diensten/voorafregeling']" fragment="wilsbeschikking">{{'last-will-subtitle' | translate}}</a>
                <a class="subtitle-link hover-link" [routerLink]="['/diensten/voorafregeling']" fragment="uitvaartverzekering">{{'funeral-assurance-subtitle' | translate}}</a>
            </div>
        </div>
    </div>
</div>