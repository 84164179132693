import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lib-field-textarea',
  templateUrl: './field-textarea.component.html',
  styleUrls: ['./field-textarea.component.scss']
})
export class FieldTextareaComponent implements OnInit {
    @Input() label?: string = '';
    @Input() afterString?: string;
    @Input() error?: string;
    @Input() placeholder?: string = '';
    @Input() model: string = '';
    @Input() rows: number = 4;
    @Output() modelChange = new EventEmitter();
    @Output() enter = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
