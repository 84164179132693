import { Component, OnInit } from '@angular/core';
import { emailObfuscationPipe } from 'src/app/utils/pipes';

@Component({
    selector: 'app-cookie-policy',
    templateUrl: './cookie-policy.component.html',
    styleUrls: ['./cookie-policy.component.scss']
})
export class CookiePolicyComponent implements OnInit {
    email: string = '';
    constructor(private emailPipe: emailObfuscationPipe) {}

    ngOnInit(): void {
        this.email = this.emailPipe.transform('privacy{at}ginkgho{dot}be');
    }
}
