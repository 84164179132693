<div class="footer-wrap" [style.background-image]="'url(' + backgroundImage + ')'">
    <div class="footer-data-wrap container">
       <div class="footer-title page-title">
            <ng-container *ngIf="establishments.length === 1">
                <h2>{{'location' | translate}}</h2>
            </ng-container>
            <ng-container *ngIf="establishments.length !== 1">
                <h2>{{'locations' | translate}}</h2>
            </ng-container>
        </div>
        <div [ngClass]="establishments.length === 1 ? 'footer-establishments--single' : 'footer-establishments'">
            <ng-container *ngFor="let est of establishments">
                <lib-location [singleLocation]="establishments.length === 1 ? true : false" [img]="est.img" [type]="est.type" [address]="est.address" [town]="est.town" [phone]="est.phone" [email]="est.email"></lib-location>
            </ng-container>
        </div>
    </div>

    <footer class="footer-terms">
        <div class="container">
            © Ginkgho {{currentYear}} - 
            <!-- <a class="hover-link" [routerLink]="['terms']">Terms & conditions</a> -  -->
            <a class="hover-link" [routerLink]="['cookiebeleid']">Cookiebeleid</a>
            <!-- <a class="hover-link" [routerLink]="['privacy-policy']">Privacy Policy</a> -->
        </div>
    </footer>
</div>
