import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { formatTsPipe, TranslatePipe } from './pipes';

@NgModule({
    declarations: [TranslatePipe, formatTsPipe],
    imports: [CommonModule],
    exports: [TranslatePipe, formatTsPipe]
})
export class PipeModule {}
