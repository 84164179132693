<div [ngClass]="singleLocation ? 'location-wrap--flex' : 'location-wrap'">
    <div class="location-img"><img [src]="img" alt="{{type}}"></div>
    <div class="location-text-wrap">
        <div class="location-type location-text">
            {{type}}
        </div>
        <div class="location-place">
            <div class="location-text">
                {{address}}
            </div>
            <div class="location-text">
                {{town}}
            </div>
            <a class="location-text hover-link" [href]="'https://www.google.com/maps/?q=' + address + ' ' + town" target="_blank">{{'route-description' | translate}}</a>
        </div>
        <div class="location-contacts">
            <div class="location-text">
                <a class="telephone-link hover-link" href="tel:{{phone}}">
                    {{phone}}
                </a>
            </div>
            <a class="location-text hover-link" [href]="'mailto:' + email" *ngIf="email">
                {{email}}
            </a>
        </div>
    </div>
</div>