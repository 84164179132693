<lib-header [bgImagePosition]="backgroundPositioning" [bgImage]="bgImage" [displayphone]="true" [image]="logoImage" classes="home-header" [phone]="phone"></lib-header>
<div class="container">
    <div class="home-wrap">
        <div class="information-wrap">
            <div class="left">
                <div class="what-to-do-wrap">
                    <div class="title">
                        {{'home-title-deceased' | translate }}
                    </div>
                    <div class="text">
                        {{'home-text-deceased' | translate }}
                    </div>
                    <a class="more-link hover-link" [routerLink]="['/diensten/bij-overlijden']">
                        {{'read-more' | translate}}
                    </a>
                </div>
                <div class="we-are-there-wrap">
                    <div class="title">
                        {{'we-are-there-title' | translate }}
                    </div>
                    <div class="text">
                        {{'we-are-there-text1' | translate }}
                        <br>
                        <br>
                        {{'we-are-there-text2' | translate }}
                    </div>
                </div>
            </div>
            <div class="deceased right">
                <div class="deceased-title">
                    {{'recent-deceased' | translate }}
                </div>
                <div class="search-wrap">
                    <lib-field-text (enter)="redirectToDeceaseds($event)" [search]="true" [showIcon]="true" [model]="deceasedName" [placeholder]="'Zoeken'"></lib-field-text>
                </div>
                <div class="results">
                    <ng-container *ngIf="!ready">
                        <lib-spinner></lib-spinner>
                    </ng-container>
                    <ng-container *ngIf="ready">
                        <ng-container *ngFor="let dec of deceased" >
                            <lib-deceased-card [size]="'small'" [deceased]="dec"></lib-deceased-card>
                        </ng-container>
                        <a class="more-link hover-link" [routerLink]="['/overlijdens']">
                            {{'show-more' | translate}}
                        </a>
                    </ng-container>
                    <!-- {{deceased | json}} -->
                </div>
            </div>
        </div>
    
        <div>
            <div class="services-wrap">
                <h1>
                    {{'our-services-title' | translate}}
                </h1>
            </div>
            <div class="card-wrap">
                <ng-container  *ngFor="let service of services">
                    <lib-service-card [img]="service.img" [title]="service.title" [text]="service.text" [redirect]="service.redirect"></lib-service-card>
                </ng-container>
            </div>
        </div>
    </div>
</div>
