<div class="field field-text">
    <span *ngIf="label" class="label">{{label}} <span *ngIf="optionalString">({{optionalString}})</span></span>
    <div class="input-wrap app-tooltip-wrap">
            <input
                [class.searchbar]="search"
                class="flex-grow-1"
                [ngStyle]="{'width': inputWidth ? inputWidth : ''}"
                [(ngModel)]="localModel" 
                (ngModelChange)="changes()"
                (keyup.enter)="enter.emit(localModel)"
                [placeholder]="placeholder"
                [class.error-field]="error"
            />
            <div tabindex="-1" *ngIf="showIcon" class="inside-input">
                <i class="icon icon-search"></i>
            </div>

        <button *ngIf="type === 'password'" (click)="togglePwd = !togglePwd" tabindex="-1" class="toggle-show">
            <ng-container *ngIf="!togglePwd"><app-button classes="lightblue-default password-button" [text]="'inlog_password_show' | translate"></app-button></ng-container><ng-container *ngIf="togglePwd"><app-button [text]="'inlog_password_hide' | translate" classes="lightblue-default password-button"></app-button></ng-container>
        </button>
    </div>
    <span *ngIf="error && error !== 'true'" class="error-field-display">{{error}}</span>
</div>