import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lib-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
    @Input() text: string = '';
    @Input() classes: string = '';
    @Input() disabled?: boolean = false;
    @Input() linkHref?: boolean = false;
    @Output() emitClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
