import { Component, Input } from '@angular/core';
import { Establishments } from '../../models/establishments';
import * as moment from 'moment';

@Component({
    selector: 'lib-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    @Input() establishments: Establishments[] = [];
    @Input() backgroundImage: string = '';
    // singleEstablishment: boolean;
    
    currentYear: number;

    constructor() {
        this.currentYear = moment().year();
    }
}
