<div class="navbar-wrap" [class.navbar-wrap--mobile]="mobileMenu" [class.navbar-wrap--mobile-menu-open]="mobileMenuOpen">
    <!-- <div class="inner-navbar-wrap"> -->
        <div class="container">
            <div class="company-info">
                <a [routerLink]="'[' + redirectTitle + ']'" (click)="toggleMobileMenu(false)">
                    <img [src]="img" alt="logo" />
                    <div class="company-name">
                        {{name}}
                    </div>
                </a>
                <div class="company-phone">
                    <a class="telephone-link" href="tel:{{phone}}">
                        {{phone}}
                    </a>
                </div>
            </div>
            <nav class="redirections-navbar" [class.active]="mobileMenuOpen">
                <ul>
                    <li><a (click)="toggleMobileMenu(false)" [routerLink]="['/home']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:false}">Home</a></li>
                    <li><a (click)="toggleMobileMenu(false)" [routerLink]="['/diensten']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:false}">Diensten</a></li>
                    <li><a (click)="toggleMobileMenu(false)" [routerLink]="['/over-ons']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:false}">Over ons</a></li>
                    <li><a (click)="toggleMobileMenu(false)" [routerLink]="['/contact']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:false}">Contact</a></li>
                    <li><a (click)="toggleMobileMenu(false)" [href]="externeLink" target="_blank" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:false}">Toegang familie</a></li>
                </ul>
            </nav>

            <ng-container *ngIf="mobileMenu">
                <i *ngIf="!mobileMenuOpen" (click)="toggleMobileMenu(true)" class="icon icon-mobile-menu"></i>
                <i *ngIf="mobileMenuOpen" (click)="toggleMobileMenu(false)" class="icon icon-close-mobile-menu"></i>
            </ng-container>

        </div>
    <!-- </div> -->
</div>
<div class="mobile-menu-spacer" *ngIf="mobileMenuOpen"></div>