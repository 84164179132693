import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { HelperService } from '../../services/helper.service';
import { TranslatePipe } from '../../utils/pipes';

@Component({
    selector: 'app-service-details',
    templateUrl: './service-details.component.html',
    styleUrls: ['./service-details.component.scss']
})
export class ServiceDetailsComponent implements OnInit {
    phone: string = '';
    headerText: string = '';

    constructor(
        private helperService: HelperService,
        private route: ActivatedRoute,
        private router: Router,
        private translatePipe: TranslatePipe
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof RoutesRecognized) {
                let route = event.state.root.firstChild;
                this.headerText = route?.firstChild?.data?.headerTitle;
                this.fillInArr();
            }
        });
    }

    inArr: any[] = [];
    myArr: any[] = [];

    ngOnInit(): void {
        this.inArr = [
            {
                title: this.translatePipe.transform('by-decease-title'),
                routerLink: 'bij-overlijden',
                links: [
                    { title: this.translatePipe.transform('wait-service-subtitle'), anchor: 'wachtdienst' },
                    {
                        title: this.translatePipe.transform('complete-funeral-care-subtitle'),
                        anchor: 'complete_uitvaartzorg'
                    },
                    { title: this.translatePipe.transform('wake-subtitle'), anchor: 'opbaring' },
                    { title: this.translatePipe.transform('transport-subtitle'), anchor: 'vervoer' }
                ]
            },
            {
                title: this.translatePipe.transform('before-funeral-title'),
                routerLink: 'voor-de-uitvaart',
                links: [
                    {
                        title: this.translatePipe.transform('personal-conversation-subtitle'),
                        anchor: 'persoonlijk_gesprek'
                    },
                    { title: this.translatePipe.transform('press-work-subtitle'), anchor: 'drukwerk' },
                    {
                        title: this.translatePipe.transform('papers-digital-social-media-subtitle'),
                        anchor: 'kranten_digitaal_sociale_media'
                    },
                    { title: this.translatePipe.transform('coffin-urn-subtitle'), anchor: 'kist_lijkwade_urne' },
                    {
                        title: this.translatePipe.transform('juwel-axle-holders-subtitle'),
                        anchor: 'herinneringsjuwelen_ashouders'
                    }
                ]
            },
            {
                title: this.translatePipe.transform('during-funeral-title'),
                routerLink: 'tijdens-de-uitvaart',
                links: [
                    { title: this.translatePipe.transform('livestream-subtitle'), anchor: 'livestream' },
                    { title: this.translatePipe.transform('ceremony-subtitle'), anchor: 'ceremonie' },
                    {
                        title: this.translatePipe.transform('coffee-table-subtitle'),
                        anchor: 'koffietafel_rouwmaaltijd'
                    }
                ]
            },
            {
                title: this.translatePipe.transform('after-funeral-title'),
                routerLink: 'na-de-uitvaart',
                links: [
                    {
                        title: this.translatePipe.transform('administrative-care-subtitle'),
                        anchor: 'administratieve_nazorg'
                    },
                    { title: this.translatePipe.transform('grave-stones-subtitle'), anchor: 'grafzerken' }
                ]
            },
            {
                title: this.translatePipe.transform('arrangement-title'),
                routerLink: 'voorafregeling',
                links: [
                    { title: this.translatePipe.transform('last-will-subtitle'), anchor: 'wilsbeschikking' },
                    {
                        title: this.translatePipe.transform('funeral-assurance-subtitle'),
                        anchor: 'uitvaartverzekering'
                    }
                ]
            }
        ]
        this.phone = this.helperService.getPhoneNumber();
        this.headerText = this.route.snapshot.firstChild.data.headerTitle;
        this.fillInArr();
    }
    fillInArr() {
        this.myArr = this.inArr.filter((item) => {
            return item.title != this.headerText;
        });
    }
}
