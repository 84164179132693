import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
    @Input() img: string = '';
    @Input() name: string = '';
    @Input() text: string = '';
    @Input() phone: string = '';
    @Input() email: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
