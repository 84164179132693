import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';

@Component({
    selector: 'app-single-view-service',
    templateUrl: './single-view-service.component.html',
    styleUrls: ['./single-view-service.component.scss']
})
export class SingleViewServiceComponent implements OnInit {
    config: any = {};
    routeDataType: string;
    constructor(private ConfigService: ConfigService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.routeDataType = this.route.snapshot.data.dataType;
        console.log(this.routeDataType);
        this.config = this.ConfigService.getConfig();
        console.log(this.config);
    }
}
