import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { ButtonModule } from '../../common/button/button.module';
import { FieldTextModule } from '../../fields/field-text/field-text.module';



@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    ButtonModule,
    FieldTextModule
  ],
  exports:[
    HeaderComponent
  ]
})
export class HeaderModule { }
